<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <ValidationObserver ref="observer">
        <b-table
            ref="refListTable"
            class="position-relative"
            :items="filterItems"
            responsive
            hover
            :fields="tableColumns"
            show-empty
            empty-text="未找到记录"
            :busy="isBusy"
            @row-hovered="onRowHovered"
        >
          <template #cell(name)="data">
            {{ data.item.name }}{{ data.item.is_gift===1?"(赠)":"" }}
            <feather-icon
                icon="TwitchIcon"
                size="21"
                v-b-tooltip.hover.html="
              '<div class=text-left>'+
              '69码：'+data.item.ext.productcode+
              '<br>'+'产品批次：'+data.item.batch_no+
              '<br>'+'开始：'+toDate(data.item.production_date)+
              '<br>'+'结束：'+toDate(data.item.expiry_date)+
              '</div>'"
                variant="success"
            />
            <feather-icon
                icon="CpuIcon"
                size="21"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="success"
                @click="function () {
                      stockOccupied.warehouse_id=data.item.warehouse_id
                      stockOccupied.product_id=data.item.product_id
                      stockOccupied.stock_id=data.item.stock_id
                      $refs['modal-stock-lock'].show()
                      $forceUpdate()
                      }"
            />
          </template>

          <template #cell(product_unit)="data">
            {{getCodeLabel('product_unit', data.item.product_unit)}}
          </template>

          <template #cell(trueqty)="{field,value,item}">
            <div>
              <xy-input :vid="`${field.key}_${item.id}`" :rules="`required|nonnegativeInteger|max_value:${item.qty}`" :val.sync="editableData[item.id][field.key]"
                        v-if="editable(item.id)" :name="field.label"
              ></xy-input>
              <div v-else>{{ value }}</div>
            </div>
          </template>

          <template #cell(warestatus)="data">
            <b-badge
                pill
                :variant="`light-${getCodeColor('stock_status', data.item.warestatus)}`"
            >
              {{ getCodeLabel("stock_status", data.item.warestatus)}}
            </b-badge>
          </template>


          <!-- Column: Actions -->
          <template #cell(actions)="row">
            <span v-if="editable(row.item.id)">
              <xy-link @click="save" class="mr-1">保存</xy-link>
<!--              <xy-pop-confirm title="确认取消？" @confirm="cancel">
                <xy-link>取消</xy-link>
              </xy-pop-confirm>-->
            </span>
            <span v-else>
              <xy-link @click="edit" class="mr-1">编辑</xy-link>
<!--               <xy-pop-confirm title="确认删除？" @confirm="del">
                <xy-link>删除</xy-link>
              </xy-pop-confirm>-->
            </span>
          </template>

        </b-table>
      </ValidationObserver>
      <div class="m-2" v-if="!_disabled" >

        <!-- Table Top -->
        <b-row >

          <b-col
              cols="12"
              md="6"
          >
            <xy-button
                variant="primary"
                @click="commit"
                v-if="commitShow"
            >
              <span class="text-nowrap">确认出库</span>
            </xy-button>
          </b-col>
          <b-col md="3">
          </b-col>
        </b-row>

      </div>
      <b-modal
          id="stockModal"
          ok-only
          ok-title="确认"
          @ok="onSelectStock"
          cancel-title="取消"
          centered
          size="xl"
          :title="'请选择'"
      >
        <stock-list
            ref="stockSelect" :inbound_item_id="inbound_item" :exclude_ids="filterItems.map(item => item.stock_id).join(',')"
        >
        </stock-list>
      </b-modal>
      <b-modal
          id="modal-stock-lock"
          ref="modal-stock-lock"
          no-close-on-backdrop
          cancel-title="取消"
          centered
          size="xl"
          title="锁库单据"
      >
        <stock-occupied-list
            :warehouse_id="stockOccupied.warehouse_id"
            :product_id="stockOccupied.product_id"
            :stock_id="stockOccupied.stock_id"
        >

        </stock-occupied-list>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BPopover
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted, toRefs, computed, watch, reactive, inject } from '@vue/composition-api'
import { getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'
import { toTime, toDate } from '@core/utils/dayjs/utils'
import purchaseoutbounditemUseList from './purchaseoutbounditemUseList'
import XyInput from '@/views/components/xy/XyInput'
import XySelect from '@/views/components/xy/XySelect'
import CodeSelect from '@/views/apps/code/CodeSelect'
import XyForm from '@/views/components/xy/XyForm'
import XyPopConfirm from '@/views/components/xy/XyPopConfirm'
import XyButton from '@/views/components/xy/XyButton'
import XyLink from '@/views/components/xy/XyLink'
import StockList from '@/views/apps/stock/StockList'
import purchaseoutbounditemStore from '@/views/apps/purchaseoutbounditem/purchaseoutbounditemStore'
import StockOccupiedList from "@/views/apps/stockoccupied/StockOccupiedList.vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    StockOccupiedList,
    StockList,
    XyLink,
    XyButton,
    XyPopConfirm,
    BPopover,
    XyForm,
    CodeSelect,
    XySelect,
    XyInput,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  data(){
    return {
      stockOccupied:{},
    }
  },
  directives: {
    Ripple,
  },
  props: ['id', 'inbound_item_id','readonly_all'],
  setup(props, { emit }) {
    if (!store.hasModule('purchaseoutbounditem')) store.registerModule('purchaseoutbounditem', purchaseoutbounditemStore)
    const { id } = toRefs(props)
    const {
      methods: useListMethods,
      state: userListState
    } = purchaseoutbounditemUseList({
      id: id.value,
      inbound_item_id:props.inbound_item_id,
      readonly_all:props.readonly_all
    }, emit)



    return {
      ...toRefs(userListState),
      ...useListMethods,

      // Filter
      getCodeOptions,
      getCodeLabel,
      getCodeColor,

      toTime,
      toDate,
      /*onSelectStock,
      showselproduct*/
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

