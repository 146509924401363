import axios from '@axios'

export default {
  namespaced: true,
  getters: {
  },
  mutations: {
  },
  actions: {
    //占用中的调拨单明细
    listOccupiedAllot(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/exportexcelfile/listOccupiedAllot', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    //占用中的销售出库单
    listOccupiedSalesOutbound(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/exportexcelfile/listOccupiedSalesOutbound', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    //占用中的销售单
    listOccupiedSalesOrder(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/exportexcelfile/listOccupiedSalesOrder', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    //占用中的箱规转换单
    listOccupiedChangeApply(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/exportexcelfile/listOccupiedChangeApply', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    //占用中的订货单
    listOccupiedPreSalesOrder(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/exportexcelfile/listOccupiedPreSalesOrder', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    //占用中的采购退料出库单
    listOccupiedPurchaseOutbound(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/exportexcelfile/listOccupiedPurchaseOutbound', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    //占用中的库位转换单
    listOccupiedStockStatusChange(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/exportexcelfile/listOccupiedStockStatusChange', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
