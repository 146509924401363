import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function stockOccupiedList(warehouse_id,product_id,stock_id) {

  // Use toast
  const toast = useToast()
  const orderNo = ref('')
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const refetchData = () => {
    refListTable1.value.refresh()
    refListTable2.value.refresh()
    refListTable3.value.refresh()
    refListTable4.value.refresh()
    refListTable5.value.refresh()
    refListTable6.value.refresh()
    refListTable7.value.refresh()
  }
  /*#占用中的调拨单明细*/
  const refListTable1 = ref(null)
  // Table Handlers
  const tableColumns1 = [
    { key: 'outboundId', label: '调拨单Id', sortable: true },
    { key: 'outboundNo', label: '调拨单号', sortable: true },
    { key: 'warehouseName', label: '调出仓库', sortable: true },
    { key: 'status', label: '调拨单状态', sortable: true },
    { key: 'dutyName', label: '责任人', sortable: true },
    { key: 'createTime', label: '创建时间', sortable: true },
    { key: 'creator', label: '创建人', sortable: true },
    { key: 'productId', label: '商品ID', sortable: true },
    { key: 'name', label: '商品名称', sortable: true },
    { key: 'stockId', label: 'stock_id', sortable: true },
    { key: 'planOutboundQty', label: '计划出库数量', sortable: true },
  ]

  const start1 = ref(1)
  const limitOption1 = [1,2,3]
  const listTotals1 = ref(0)
  const orderBy1 = ref('t2.outbound_id')
  const isSortDirDesc1 = ref(true)

  const dataMeta1 = computed(() => {
    const localItemsCount = refListTable1.value ? refListTable1.value.localItems.length : 0
    return {
      from: limit.value * (start1.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start1.value - 1) + localItemsCount,
      of: listTotals1.value,
      timeLineShow: localItemsCount > 0 ? true : false,
    }
  })
  watch([start1, limit], () => {
    refListTable1.value.refresh()
  })
  const dataList1 = (ctx, callback) => {
    store
        .dispatch('stockoccupied/listOccupiedAllot', {
          start: start1.value,
          limit: limit.value,
          order_by: orderBy1.value,
          order_desc: isSortDirDesc1.value === true ? 'desc':'',
          warehouse_id: warehouse_id,
          product_id: product_id,
          stock_id: stock_id,
        })
        .then(response => {
          const data = response.data
          if(data.code ===0){
            const list = data.data.list
            listTotals1.value = data.data.ext.totals
            callback(list)
          }
        })
        .catch((e) => {
          toast({
            component: ToastificationContent,
            props: {
              title: '占用中的调拨单明细列表获取错误',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
  }

  /*#占用中的销售出库单*/
  const refListTable2 = ref(null)
  // Table Handlers
  const tableColumns2 = [
    { key: 'outboundId', label: '销售出库单Id', sortable: true },
    { key: 'outboundNo', label: '销售出库单号', sortable: true },
    { key: 'orderId', label: '销售单Id', sortable: true },
    { key: 'orderNo', label: '销售单号', sortable: true },
    { key: 'status', label: '销售出库单状态', sortable: true },
    { key: 'ourwarehouseName', label: '出库仓库', sortable: true },
    { key: 'storeName', label: '店铺/团队名称', sortable: true },
    { key: 'creator', label: '创建人', sortable: true },
    { key: 'addTime', label: '创建时间', sortable: true },
    { key: 'productId', label: '商品ID', sortable: true },
    { key: 'name', label: '商品名称', sortable: true },
    { key: 'stockId', label: 'stock_id', sortable: true },
    { key: 'qty', label: '计划出库数量', sortable: true },

  ]

  const start2 = ref(1)
  const limitOption2 = [1,2,3]
  const listTotals2 = ref(0)
  const orderBy2 = ref('t2.outbound_id')
  const isSortDirDesc2 = ref(true)

  const dataMeta2 = computed(() => {
    const localItemsCount = refListTable2.value ? refListTable2.value.localItems.length : 0
    return {
      from: limit.value * (start2.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start2.value - 1) + localItemsCount,
      of: listTotals2.value,
      timeLineShow: localItemsCount > 0 ? true : false,
    }
  })
  watch([start2, limit], () => {
    refListTable2.value.refresh()
  })
  const dataList2 = (ctx, callback) => {
    store
        .dispatch('stockoccupied/listOccupiedSalesOutbound', {
          start: start2.value,
          limit: limit.value,
          order_by: orderBy2.value,
          order_desc: isSortDirDesc2.value === true ? 'desc':'',
          warehouse_id: warehouse_id,
          product_id: product_id,
          stock_id: stock_id,
        })
        .then(response => {
          const data = response.data
          if(data.code ===0){
            const list = data.data.list
            listTotals2.value = data.data.ext.totals
            callback(list)
          }
        })
        .catch((e) => {
          toast({
            component: ToastificationContent,
            props: {
              title: '占用中的销售出库单列表获取错误',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
  }

  /*占用中的销售单*/
  const refListTable3 = ref(null)
  // Table Handlers
  const tableColumns3 = [
    { key: 'orderId', label: '销售单Id', sortable: true },
    { key: 'orderNo', label: '销售单号', sortable: true },
    { key: 'status', label: '销售单状态', sortable: true },
    { key: 'salesType', label: '销售分类', sortable: true },
    { key: 'warehouseName', label: '出库仓库', sortable: true },
    { key: 'salesName', label: '销售员', sortable: true },
    { key: 'creator', label: '创建人', sortable: true },
    { key: 'addTime', label: '创建时间', sortable: true },
    { key: 'productId', label: '商品ID', sortable: true },
    { key: 'name', label: '商品名称', sortable: true },
    { key: 'stockId', label: 'stock_id', sortable: true },
    { key: 'qty', label: '销售数量', sortable: true },

  ]

  const start3 = ref(1)
  const limitOption3 = [1,2,3]
  const listTotals3 = ref(0)
  const orderBy3 = ref('t2.order_id')
  const isSortDirDesc3 = ref(true)

  const dataMeta3 = computed(() => {
    const localItemsCount = refListTable3.value ? refListTable3.value.localItems.length : 0
    return {
      from: limit.value * (start3.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start3.value - 1) + localItemsCount,
      of: listTotals3.value,
      timeLineShow: localItemsCount > 0 ? true : false,
    }
  })
  watch([start3, limit], () => {
    refListTable3.value.refresh()
  })

  const dataList3 = (ctx, callback) => {
    store
        .dispatch('stockoccupied/listOccupiedSalesOrder', {
          start: start3.value,
          limit: limit.value,
          order_by: orderBy3.value,
          order_desc: isSortDirDesc3.value === true ? 'desc':'',
          warehouse_id: warehouse_id,
          product_id: product_id,
          stock_id: stock_id,
        })
        .then(response => {
          const data = response.data
          if(data.code ===0){
            const list = data.data.list
            listTotals3.value = data.data.ext.totals
            callback(list)
          }
        })
        .catch((e) => {
          toast({
            component: ToastificationContent,
            props: {
              title: '占用中的销售单列表获取错误',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
  }
  /*占用中的箱规转换单*/
  const refListTable4 = ref(null)
  // Table Handlers
  const tableColumns4 = [
    { key: 'changeId', label: '转换单Id', sortable: true },
    { key: 'changeNo', label: '转换单号', sortable: true },
    { key: 'status', label: '转换单审批状态', sortable: true },
    { key: 'creator', label: '销售员', sortable: true },
    { key: 'addTime', label: '创建时间', sortable: true },
    { key: 'warehouseName', label: '所在仓库', sortable: true },
    { key: 'productId', label: '商品ID', sortable: true },
    { key: 'boxQuantity', label: '待转换商品箱规', sortable: true },
    { key: 'name', label: '商品名称', sortable: true },
    { key: 'stockId', label: 'stock_id', sortable: true },
    { key: 'oldsubqty', label: '待转换数量', sortable: true },

  ]

  const start4 = ref(1)
  const limitOption4 = [1,2,3]
  const listTotals4 = ref(0)
  const orderBy4 = ref('t1.change_id')
  const isSortDirDesc4 = ref(true)

  const dataMeta4 = computed(() => {
    const localItemsCount = refListTable4.value ? refListTable4.value.localItems.length : 0
    return {
      from: limit.value * (start4.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start4.value - 1) + localItemsCount,
      of: listTotals4.value,
      timeLineShow: localItemsCount > 0 ? true : false,
    }
  })
  watch([start4, limit], () => {
    refListTable4.value.refresh()
  })

  const dataList4 = (ctx, callback) => {
    store
        .dispatch('stockoccupied/listOccupiedChangeApply', {
          start: start4.value,
          limit: limit.value,
          order_by: orderBy4.value,
          order_desc: isSortDirDesc4.value === true ? 'desc':'',
          warehouse_id: warehouse_id,
          product_id: product_id,
          stock_id: stock_id,
        })
        .then(response => {
          const data = response.data
          if(data.code ===0){
            const list = data.data.list
            listTotals4.value = data.data.ext.totals
            callback(list)
          }
        })
        .catch((e) => {
          toast({
            component: ToastificationContent,
            props: {
              title: '占用中的箱规转换单列表获取错误',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
  }
  /*#占用中的订货单*/
  const refListTable5 = ref(null)
  // Table Handlers
  const tableColumns5 = [
    { key: 'orderId', label: '订货单Id', sortable: true },
    { key: 'orderNo', label: '订货单号', sortable: true },
    { key: 'salesName', label: '销售员', sortable: true },
    { key: 'warehouseName', label: '所在仓库', sortable: true },
    { key: 'status', label: '订货单状态', sortable: true },
    { key: 'creator', label: '创建人', sortable: true },
    { key: 'addTime', label: '创建时间', sortable: true },
    { key: 'productId', label: '商品ID', sortable: true },
    { key: 'name', label: '商品名称', sortable: true },
    { key: 'salesQty', label: '销售数量', sortable: true },

  ]

  const start5 = ref(1)
  const limitOption5 = [1,2,3]
  const listTotals5 = ref(0)
  const orderBy5 = ref('t2.order_id')
  const isSortDirDesc5 = ref(true)

  const dataMeta5 = computed(() => {
    const localItemsCount = refListTable5.value ? refListTable5.value.localItems.length : 0
    return {
      from: limit.value * (start5.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start5.value - 1) + localItemsCount,
      of: listTotals5.value,
      timeLineShow: localItemsCount > 0 ? true : false,
    }
  })
  watch([start5, limit], () => {
    refListTable5.value.refresh()
  })

  const dataList5 = (ctx, callback) => {
    store
        .dispatch('stockoccupied/listOccupiedPreSalesOrder', {
          start: start5.value,
          limit: limit.value,
          order_by: orderBy5.value,
          order_desc: isSortDirDesc5.value === true ? 'desc':'',
          warehouse_id: warehouse_id,
          product_id: product_id,
          stock_id: stock_id,
        })
        .then(response => {
          const data = response.data
          if(data.code ===0){
            const list = data.data.list
            listTotals5.value = data.data.ext.totals
            callback(list)
          }
        })
        .catch((e) => {
          toast({
            component: ToastificationContent,
            props: {
              title: '占用中的订货单列表获取错误',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
  }
  /*占用中的采购退料出库单*/
  const refListTable6 = ref(null)
  // Table Handlers
  const tableColumns6 = [
    { key: 'orderId', label: '退料单Id', sortable: true },
    { key: 'orderNo', label: '退料单号', sortable: true },
    { key: 'status', label: '退料单状态', sortable: true },
    { key: 'creator', label: '创建人', sortable: true },
    { key: 'addTime', label: '创建时间', sortable: true },
    { key: 'warehouseName', label: '所在仓库', sortable: true },
    { key: 'productId', label: '商品ID', sortable: true },
    { key: 'name', label: '商品名称', sortable: true },
    { key: 'stockId', label: 'stock_id', sortable: true },
    { key: 'qty', label: '待退数量', sortable: true },

  ]

  const start6 = ref(1)
  const limitOption6 = [1,2,3]
  const listTotals6 = ref(0)
  const orderBy6 = ref('t2.order_id')
  const isSortDirDesc6 = ref(false)

  const dataMeta6 = computed(() => {
    const localItemsCount = refListTable6.value ? refListTable6.value.localItems.length : 0
    return {
      from: limit.value * (start6.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start6.value - 1) + localItemsCount,
      of: listTotals6.value,
      timeLineShow: localItemsCount > 0 ? true : false,
    }
  })
  watch([start6, limit], () => {
    refListTable6.value.refresh()
  })

  const dataList6 = (ctx, callback) => {
    store
        .dispatch('stockoccupied/listOccupiedPurchaseOutbound', {
          start: start6.value,
          limit: limit.value,
          order_by: orderBy6.value,
          order_desc: isSortDirDesc6.value === true ? 'desc':'',
          warehouse_id: warehouse_id,
          product_id: product_id,
          stock_id: stock_id,
        })
        .then(response => {
          const data = response.data
          if(data.code ===0){
            const list = data.data.list
            listTotals6.value = data.data.ext.totals
            callback(list)
          }
        })
        .catch((e) => {
          toast({
            component: ToastificationContent,
            props: {
              title: '占用中的采购退料出库单列表获取错误',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
  }
  /*#占用中的库位转换单*/
  const refListTable7 = ref(null)
  // Table Handlers
  const tableColumns7 = [
    { key: 'changeId', label: '转换单Id', sortable: true },
    { key: 'changeNo', label: '转换单号', sortable: true },
    { key: 'status', label: '转换单审批状态', sortable: true },
    { key: 'creator', label: '创建人', sortable: true },
    { key: 'addTime', label: '创建时间', sortable: true },
    { key: 'warehouseName', label: '所在仓库', sortable: true },
    { key: 'productId', label: '商品ID', sortable: true },
    { key: 'name', label: '商品名称', sortable: true },
    { key: 'stockId', label: 'stock_id', sortable: true },
    { key: 'stockQty', label: 'stockQty', sortable: true },

  ]

  const start7 = ref(1)
  const limitOption7 = [1,2,3]
  const listTotals7 = ref(0)
  const orderBy7 = ref('t1.change_id')
  const isSortDirDesc7 = ref(false)

  const dataMeta7 = computed(() => {
    const localItemsCount = refListTable7.value ? refListTable7.value.localItems.length : 0
    return {
      from: limit.value * (start7.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start7.value - 1) + localItemsCount,
      of: listTotals7.value,
      timeLineShow: localItemsCount > 0 ? true : false,
    }
  })
  watch([start7, limit], () => {
    refListTable7.value.refresh()
  })

  const dataList7 = (ctx, callback) => {
    store
        .dispatch('stockoccupied/listOccupiedStockStatusChange', {
          start: start7.value,
          limit: limit.value,
          order_by: orderBy7.value,
          order_desc: isSortDirDesc7.value === true ? 'desc':'',
          warehouse_id: warehouse_id,
          product_id: product_id,
          stock_id: stock_id,
        })
        .then(response => {
          const data = response.data
          if(data.code ===0){
            const list = data.data.list
            listTotals7.value = data.data.ext.totals
            callback(list)
          }
        })
        .catch((e) => {
          toast({
            component: ToastificationContent,
            props: {
              title: '占用中的库位转换单列表获取错误',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {

    limitOptions,
    limit,
    refetchData,

    /*#占用中的调拨单明细*/
    dataList1,
    tableColumns1,
    start1,
    listTotals1: listTotals1,
    dataMeta1,
    orderBy1,
    isSortDirDesc1,
    refListTable1,
    limitOption1,

    /*#占用中的销售出库单*/
    dataList2,
    tableColumns2,
    start2,
    listTotals2: listTotals2,
    dataMeta2,
    orderBy2,
    isSortDirDesc2,
    refListTable2,
    limitOption2,

    /*#占用中的销售单*/
    dataList3,
    tableColumns3,
    start3,
    listTotals3: listTotals3,
    dataMeta3,
    orderBy3,
    isSortDirDesc3,
    refListTable3,
    limitOption3,

    /*#占用中的箱规转换单*/
    dataList4,
    tableColumns4,
    start4,
    listTotals4: listTotals4,
    dataMeta4,
    orderBy4,
    isSortDirDesc4,
    refListTable4,
    limitOption4,

    /*#占用中的订货单*/
    dataList5,
    tableColumns5,
    start5,
    listTotals5: listTotals5,
    dataMeta5,
    orderBy5,
    isSortDirDesc5,
    refListTable5,
    limitOption5,

    /*#占用中的采购退料出库单*/
    dataList6,
    tableColumns6,
    start6,
    listTotals6: listTotals6,
    dataMeta6,
    orderBy6,
    isSortDirDesc6,
    refListTable6,
    limitOption6,

    /*#占用中的库位转换单*/
    dataList7,
    tableColumns7,
    start7,
    listTotals7: listTotals7,
    dataMeta7,
    orderBy7,
    isSortDirDesc7,
    refListTable7,
    limitOption7,

    // 搜索条件
    orderNo,
  }
}
