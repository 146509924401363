<template>
  <b-card style="width:100%">
    <b-form
        @submit.prevent="refreshTable"
        @reset.prevent="resetCondition"
    >
      <b-row>
        <b-col v-for="(value,name,index) in page" :key="index" md="3">
          <xy-form :prop-data="value" v-model="condition[name]"></xy-form>
        </b-col>

        <b-col md="3">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="仓库"
              label-for="type"
              label-size="sm"
          >
            <v-select
                v-model="condition.warehouseIds"
                label="label"
                :options="getCodeOptions('warehouse')"
                multiple
                class="select-size-sm"
                placeholder="请选择仓库"
            />
          </b-form-group>
        </b-col>

        <b-col
            cols="12"
            md="6"
        >
          <b-button
              variant="outline-primary"
              type="submit"
              class="mr-1"
          >
            <span class="align-right">查询</span>
          </b-button>

          <b-button
              variant="outline-primary"
              type="reset"
              class="mr-1"
          >
            <span class="align-right">重置</span>
          </b-button>

          <b-button
              variant="outline-primary"
              @click="exportExcel"
          >
            <span class="align-right">导出</span>
          </b-button>

        </b-col>
        <b-modal
            id="modal-single"
            ok-only
            ok-title="确认"
            @ok="onSelectSingle"
            cancel-title="取消"
            centered
            size="lg"
            :title="'请选择'+modalName"
            ref="singleModal"
        >

          <company-select
              ref="companySelect" :companyType="companyType" v-if="modalName === '签约主体' || modalName === '供应商' "
          />

          <user-select
              ref="creatorSelect" :department-id="1" v-else-if="'申请人' === modalName "
          />

        </b-modal>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import { computed, onMounted, onUnmounted, reactive, toRefs } from '@vue/composition-api'
import {getCodeOptions, toTime} from '@core/utils/filter'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import CompanySelect from '@/views/apps/company/CompanySelect'
import UserSelect from '@/views/apps/user/user-modal/UserSelect'
import XyDatePicker from '@/views/components/xy/XyDatePicker'
import XyForm from '@/views/components/xy/XyForm'
import purchaseoutboundStore from '@/views/apps/purchaseoutbound/purchaseoutboundStore'
import axios from "@/libs/axios";

export default {
  components: { XyForm, XyDatePicker, UserSelect, CompanySelect },
  props: {},
  setup(props, { emit }) {
    const toast = useToast()
    // Register module
    if (!store.hasModule('purchaseoutbound')) store.registerModule('purchaseoutbound', purchaseoutboundStore)

    onMounted(() => {
      state.condition = store.getters['purchaseoutbound/getCondition']
    })

    const state = reactive({
      condition: {
        supplierName: undefined,
        creatorName: undefined,
      },
      companyType: '',
      modalName: '',
      singleModal:undefined,
      page:{
        outbound_no: {
          type: 'input',
          attrs: {
            id: 'outbound_no',
            label: '退料出库单编号',
          },
        },
        reason: {
          type: 'select',
          attrs: {
            id: 'reason',
            label: '退货原因',
            optionsType: 'purchase_returnbound_reason',
          },
        },
        /*is_refund: {
          type: 'select',
          attrs: {
            id: 'is_refund',
            label: '是否退款',
            disabled: computed(() => state.is_refund_disabled),
            optionsType: 'yesno',
          },
        },*/
        supplierName: {
          type: 'input',
          attrs: {
            id: 'supplierName',
            label: '供应商',
            readonly: true,
            placeholder: '点击搜索供应商',
          },
          on: {
            click: () => methods.showSingleModal('供应商')
          }
        },
        order_no: {
          type: 'input',
          attrs: {
            id: 'order_no',
            label: '采购单编号',
          },
        },
        product_name: {
          type: 'input',
          attrs: {
            id: 'product_name',
            label: '产品名称',
          },
        },
        creatorName: {
          type: 'input',
          attrs: {
            id: 'creatorName',
            label: '申请人',
            readonly: true,
            placeholder: '点击搜索申请人',
          },
          on: {
            click: () => methods.showSingleModal('申请人')
          }
        },
        status: {
          type: 'select',
          attrs: {
            id: 'status',
            label: '状态',
            optionsType: 'purchase_outbound_status',
          },
        },
      },
    })
    const methods = {
      showSingleModal(modalName) {
        if (modalName === '签约主体') {
          state.companyType = 'Group'
        } else if (modalName === '供应商') {
          state.companyType = 'Supplier'
        }

        state.modalName = modalName
        state.singleModal.show()
      },
      onSelectSingle() {
        let data = {}
        switch (state.modalName) {
          case '签约主体': {
            data = this.$refs.companySelect.getSelected()
            if (data.length > 0) {
              state.condition.company_id = data[0].company_id
              state.condition.companyName = data[0].company_name
            }
            break
          }
          case '供应商': {
            data = this.$refs.companySelect.getSelected()
            if (data.length > 0) {
              state.condition.supplier_id = data[0].company_id
              state.condition.supplierName = data[0].company_name
            }
            break
          }
          case '申请人': {
            data = this.$refs.creatorSelect.getSelected()
            if (data.length > 0) {
              state.condition.creator = data[0].user_id
              state.condition.creatorName = data[0].full_name
            }
            break
          }
        }
      },
      refreshTable() {
        if(state.condition.warehouseIds){
          let ids = state.condition.warehouseIds.map(item => item.value).join(",")
          state.condition.ids = ids
        }
        let time = state.condition.time
        if (time && !time.includes('至')) {
          toast.error("请选择范围日期")
          return;
        }
        emit('refreshTable')
      },
      resetCondition() {
        state.condition = {
          supplierName: undefined,
          creatorName: undefined,
        }
        store.commit('purchaseoutbound/updateCondition', state.condition)
        methods.refreshTable()
      },
    }

    const exportExcel = function (){
      if(state.condition.warehouseIds){
        let ids = state.condition.warehouseIds.map(item => item.value).join(",")
        state.condition.ids = ids
      }
      const params = {
        outbound_no:state.condition.outbound_no,
        reason:state.condition.reason,
        supplier_name:state.condition.supplierName,
        order_no:state.condition.order_no,
        product_name:state.condition.product_name,
        creatorId:state.condition.creator,
        status:state.condition.status,
        ids:state.condition.ids
      }
      axios({
        url: '/api/exportexcelfile/purchaseoutboundExcel',
        method: 'get',
        params: params,
        responseType: 'blob',
      }).then(res => {
        toast.success('导出成功')
        let data = new Blob([res.data], {type: 'application/vnd.ms-excel'})   //接收的是blob，若接收的是文件流，需要转化一下
        let filename = '采购退料出库' + toTime(new Date().getTime() /1000)
        /*-------------*/
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = URL.createObjectURL(data)
        link.setAttribute('download', filename + '.xlsx')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    }
    return {
      ...toRefs(state),
      ...methods,
      //filter
      getCodeOptions,
      exportExcel,
    }
  },
}
</script>

<style scoped>

</style>
