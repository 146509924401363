import { ref, watch, computed, reactive, onMounted, inject, provide } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { add, multiply, subtract } from '@core/utils/math/utils'
import { getCodeLabel } from '@core/utils/filter'
import { getUserData } from '@/auth/utils'

export default function purchaseoutbounditemUseList(config, emit) {
  // Use toast
  const toast = useToast()

  const getList = async () => {
    if (config.id) {
      state.isBusy = true
      const res = await store.dispatch('purchaseoutbounditem/list', { outbound_id: config.id })
      state.isBusy = false
      if (res['data'].code === 0) {
        state.items = res['data'].data.ext.list
        state.inbound_item = state.items[0].inbounditem_id
      } else {
        toast.error('明细列表获取错误')
      }
    }
  }

  const curKey = ref('')

  const updateReadonly = (readonly) => {
    const value = { ...filterItem.value }
    if (value) {
      value.readonly = readonly
    }
    updateEditableData(value)
  }

  const updateEditableData = (value) => {
    const obj = {}
    obj[curKey.value] = value
    state.editableData = Object.assign({}, state.editableData, obj)
  }


  const filterItem = computed(() => {
    return state.items.filter(item => item.id === curKey.value)[0]
  })

  const validateRow = async function () {
    let refs = state.observer.refs
    let validArr = []
    for (const key in refs) {
      if (refs.hasOwnProperty(key) && key.includes(curKey.value)) {
        let validate = await refs[key].validate()
        if (!validate['valid']) {
          validArr.push(key)
        }
      }
    }
    return validArr.length === 0
  }

  const updateAmount = () => {
    const qty = state.filterItems.reduce((acc, cur) => add(acc, cur.qty), 0)
    const amount = state.filterItems.reduce((acc, cur) => add(acc, multiply(cur.qty,cur.product_cost_tax)), 0)
    emit('updateAmount', qty, amount)
  }
  const methods = {
    showOrderModal() {
      state.orderModal.show()
    },
    onRowDblClicked(item) {

      const curItem = state.editableData[curKey.value]
      curItem['order_id'] = item.order_id
      curItem['order_no'] = item.order_no
      updateEditableData(curItem)
    },
    editable(key) {
      return state.editableData[key] && !state.editableData[key].readonly
    },
    onRowHovered(item) {
      curKey.value = item.id
    },
    cancel() {
      updateReadonly(true)

    },
    edit() {
      updateReadonly(false)
    },
    del() {
      this.cancel()
      filterItem.value.state = 0
      updateAmount()
    },
    async commit(){
      state.commitShow = false
      const editableData = state.editableData
      const editingData = Object.values(editableData)
        .filter(item => !item || !item.readonly)
      if (editingData.length > 0) {
        toast.error('请保存列表未保存数据')
        state.commitShow = true
        return
      }
      if (state.filterItems.length === 0){
        toast.error('无明细不可保存')
        state.commitShow = true
        return
      }
      const items = state.items
      store.dispatch('purchaseoutbounditem/commit', { items:items })
        .then(res => {
          if (res.data.code === 0) {
            state.commitShow = true
            toast.success('数据已保存!')
            emit('refresh', 'refresh')
          } else {
            state.commitShow = true
            toast.error(res.data.data)
          }
        })
    },
    async save() {
      const success = await validateRow()
      if (!success) {
        toast.error('请正确填写必填字段')
        return
      }
      filterItem.value._showDetails = false
      Object.assign(filterItem.value, state.editableData[curKey.value])
      updateAmount()
      this.cancel()
    },
    refresh() {
      state.refListTable.refresh()
    },
    onSelectStock() {
      const itemList = this.$refs.stockSelect.getChecked()
      if (itemList.length > 0) {
        for (let i = 0; i < itemList.length; i++) {
          let item = itemList[i]
          item.ext.stock_qty = item.qty
          const newData = {
            id: 'new' + state.items.length,
            inbounditem_id:state.inbound_item,
            orderitem_id:item.purchaseorder_itemid,
            warehouse_id:item.warehouse_id,
            warehouse_name:item.warehouse_name,
            product_id:item.product_id,
            name:item.name,
            product_unit:item.ext.productunit,
            stock_id:item.stock_id,
            batch_no:item.batch_no,
            production_date:item.production_date,
            expiry_date:item.expiry_date,
            warestatus:item.status,
            product_cost_tax:item.cost,
            qty:0,
            trueqty:0,
            state:1,

            ext:item.ext,
          }
          state.items.push(newData)
          curKey.value = newData.id
          this.edit()
        }
      }
    },
    showselproduct() {
      this.$bvModal.show('stockModal')
    }
  }

  const state = reactive({

    tableColumns: [
      {
        key: 'name',
        label: '名称',
        sortable: true
      },
      {
        key: 'product_unit',
        label: '单位',
      },
      {
        key: 'warestatus',
        label: '库存状态',
      },
      {
        key: 'warehouse_name',
        label: '仓库',
      },
      /*{
        key: 'product_cost_tax',
        label: '含税单价',
      },*/
      {
        key: 'stock_qty',
        label: '库存数量',
        formatter:(value, key, item)=> item.ext.stock_qty
      },
      {
        key: 'qty',
        label: '退货数量',
      },
      {
        key: 'trueqty',
        label: '实际退货数量',
      },
      {
        key: 'actions',
        label: '操作'
      },
    ],

    items: [],
    isBusy: false,
    refListTable: undefined,

    editableData: reactive({}),

    inbound_item:config.inbound_item_id,
    observer: undefined,
    filterItems: computed(() => state.items.filter(item => item.state === 1)),
    _disabled:false,

    commitShow:true,
  })

  onMounted(async () => {
    const user = getUserData()
    const auth = user.role_id === 9 || user.role_id === 10 || user.role_id === 17 || user.role_id === 18 || user.role_id === 14
    state._disabled = config.readonly_all || !auth
    provide('_disabled', state._disabled)
    state.tableColumns = state._disabled ? state.tableColumns.filter(item =>  item.key !== 'actions')
      : state.tableColumns
    await getList()
    if (!state._disabled){
      state.filterItems.forEach(item =>{
        curKey.value = item.id
        methods.edit()
      })
    }

  })


  return {
    methods,
    state,
  }
}
