<template>

  <div>

    <b-card
        style="width:100%"
    >
      <div>
        <b-row>
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>
        </b-row>
<!--        <b-card>

          <b-form
              @submit.prevent="refreshTable"
              @reset.prevent="resetCondition"
          >

            <b-row>
              &lt;!&ndash;  订单号  &ndash;&gt;
              <b-col
                  cols="12"
                  md="3"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="订单号:"
                    label-for="orderNo"
                    label-size="sm"
                    class="required"
                >
                  <b-form-input
                      id="orderNo"
                      v-model="orderNo"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="3"
              >
                <b-button
                    variant="outline-primary"
                    type="submit"
                    class="mr-1"
                >
                  <span class="align-right">查询</span>
                </b-button>

                <b-button
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>

              </b-col>
            </b-row>
          </b-form>
        </b-card>-->
      </div>
    </b-card>

    <b-row>
      <b-col
          cols="12"
          md="12"
      >
        <b-card title="库存占用">
          <app-timeline>

            <!-- 占用中的调拨单明细 -->
            <app-timeline-item
                variant="success"
                icon="FileTextIcon"
            >

              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h6 v-show="!dataMeta1.timeLineShow">无占用中的调拨单明细</h6><h6 v-show="dataMeta1.timeLineShow">占用中的调拨单明细</h6>
              </div>
              <b-table
                  id="table1"
                  v-show="dataMeta1.timeLineShow"
                  ref="refListTable1"
                  class="position-relative"
                  :items="dataList1"
                  responsive
                  :fields="tableColumns1"
                  primary-key="itemId"
                  :sort-by.sync="orderBy1"
                  show-empty
                  empty-text="未找到记录"
                  :sort-desc.sync="isSortDirDesc1"
              >

                <!-- Columns -->
                <template #cell(outboundId)="data">
                  {{ data.item.outbound_id }}
                </template>
                <template #cell(outboundNo)="data">
                  {{ data.item.outbound_no }}
                </template>
                <template #cell(warehouseName)="data">
                  {{ data.item.warehouse_name }}
                </template>
                <template #cell(status)="data">
                  {{ getCodeLabel('allot_status', data.item.status) }}
                </template>
                <template #cell(dutyName)="data">
                  {{ data.item.duty_name }}
                </template>
                <template #cell(createTime)="data">
                  {{ toDate(data.item.create_time) }}
                </template>
                <template #cell(creator)="data">
                  {{ getCodeLabel('user', data.item.creator) }}
                </template>
                <template #cell(productId)="data">
                  {{ data.item.product_id }}
                </template>
                <template #cell(name)="data">
                  {{ data.item.name }}
                </template>
                <template #cell(stockId)="data">
                  {{ data.item.stock_id }}
                </template>
                <template #cell(planOutboundQty)="data">
                  {{ data.item.plan_outbound_qty }}
                </template>

              </b-table>
              <div class="mx-2 mb-2" v-show="dataMeta1.timeLineShow">
                <b-row>

                  <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-start"
                  >
                    <span class="text-muted">显示 {{ dataMeta1.from }} - {{ dataMeta1.to }} / {{ dataMeta1.of }} 记录</span>
                  </b-col>
                  <!-- Pagination -->
                  <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  >

                    <b-pagination
                        v-model="start1"
                        :total-rows="listTotals1"
                        :per-page="limit"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                        />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </div>
            </app-timeline-item>
            <!-- 占用中的销售出库单 -->
            <app-timeline-item
                variant="success"
                icon="FileTextIcon"
            >

              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h6 v-show="!dataMeta2.timeLineShow">无占用中的销售出库单</h6><h6 v-show="dataMeta2.timeLineShow">占用中的销售出库单</h6>
              </div>
              <b-table
                  id="table2"
                  v-show="dataMeta2.timeLineShow"
                  ref="refListTable2"
                  class="position-relative"
                  :items="dataList2"
                  responsive
                  :fields="tableColumns2"
                  primary-key="inbounditem_id"
                  :sort-by.sync="orderBy2"
                  show-empty
                  empty-text="未找到记录"
                  :sort-desc.sync="isSortDirDesc2"
              >

                <!-- Columns -->
                <template #cell(outboundId)="data">
                  {{ data.item.outbound_id }}
                </template>
                <template #cell(outboundNo)="data">
                  {{ data.item.outbound_no }}
                </template>
                <template #cell(orderId)="data">
                  {{ data.item.order_id }}
                </template>
                <template #cell(orderNo)="data">
                  {{ data.item.order_no }}
                </template>
                <template #cell(status)="data">
                  {{ getCodeLabel('sale_outbound_status', data.item.status) }}
                </template>
                <template #cell(ourwarehouseName)="data">
                  {{ data.item.ourwarehouse_name }}
                </template>
                <template #cell(storeName)="data">
                  {{ data.item.store_name }}
                </template>
                <template #cell(creator)="data">
                  {{ getCodeLabel('user', data.item.creator) }}
                </template>
                <template #cell(addTime)="data">
                  {{ toDate(data.item.add_time) }}
                </template>
                <template #cell(productId)="data">
                  {{ data.item.product_id }}
                </template>
                <template #cell(name)="data">
                  {{ data.item.name }}
                </template>
                <template #cell(stockId)="data">
                  {{ data.item.stock_id }}
                </template>
                <template #cell(qty)="data">
                  {{ data.item.qty }}
                </template>


              </b-table>
              <div class="mx-2 mb-2" v-show="dataMeta2.timeLineShow">
                <b-row>

                  <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-start"
                  >
                    <span class="text-muted">显示 {{ dataMeta2.from }} - {{ dataMeta2.to }} / {{ dataMeta2.of }} 记录</span>
                  </b-col>
                  <!-- Pagination -->
                  <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  >

                    <b-pagination
                        v-model="start2"
                        :total-rows="listTotals2"
                        :per-page="limit"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                        />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </div>
            </app-timeline-item>
            <!-- 占用中的销售单 -->
            <app-timeline-item
                variant="success"
                icon="FileTextIcon"
            >

              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h6 v-show="!dataMeta3.timeLineShow">无占用中的销售单</h6><h6 v-show="dataMeta3.timeLineShow">占用中的销售单</h6>
              </div>
              <b-table
                  id="table3"
                  v-show="dataMeta3.timeLineShow"
                  ref="refListTable3"
                  class="position-relative"
                  :items="dataList3"
                  responsive
                  :fields="tableColumns3"
                  primary-key="f_payment_item_id"
                  :sort-by.sync="orderBy3"
                  show-empty
                  empty-text="未找到记录"
                  :sort-desc.sync="isSortDirDesc3"
              >

                <!-- Columns -->
                <template #cell(orderId)="data">
                  {{ data.item.order_id }}
                </template>
                <template #cell(orderNo)="data">
                  {{ data.item.order_no }}
                </template>
                <template #cell(status)="data">
                  {{ getCodeLabel('contract_status', data.item.status) }}
                </template>
                <template #cell(salesType)="data">
                  {{ data.item.sales_type }}
                </template>
                <template #cell(warehouseName)="data">
                  {{ data.item.warehouse_name }}
                </template>
                <template #cell(salesName)="data">
                  {{ data.item.sales_name }}
                </template>
                <template #cell(creator)="data">
                  {{ getCodeLabel('user', data.item.creator) }}
                </template>
                <template #cell(addTime)="data">
                  {{ toDate(data.item.add_time) }}
                </template>
                <template #cell(productId)="data">
                  {{ data.item.product_id }}
                </template>
                <template #cell(name)="data">
                  {{ data.item.name }}
                </template>
                <template #cell(stockId)="data">
                  {{ data.item.stock_id }}
                </template>
                <template #cell(qty)="data">
                  {{ data.item.qty }}
                </template>

              </b-table>
              <div class="mx-2 mb-2" v-show="dataMeta3.timeLineShow">
                <b-row>

                  <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-start"
                  >
                    <span class="text-muted">显示 {{ dataMeta3.from }} - {{ dataMeta3.to }} / {{ dataMeta3.of }} 记录</span>
                  </b-col>
                  <!-- Pagination -->
                  <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  >

                    <b-pagination
                        v-model="start3"
                        :total-rows="listTotals3"
                        :per-page="limit"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                        />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </div>
            </app-timeline-item>
            <!-- 占用中的箱规转换单 -->
            <app-timeline-item
                variant="success"
                icon="FileTextIcon"
            >

              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h6 v-show="!dataMeta4.timeLineShow">无占用中的箱规转换单</h6><h6 v-show="dataMeta4.timeLineShow">占用中的箱规转换单</h6>
              </div>
              <b-table
                  id="table4"
                  v-show="dataMeta4.timeLineShow"
                  ref="refListTable4"
                  class="position-relative"
                  :items="dataList4"
                  responsive
                  :fields="tableColumns4"
                  primary-key="returnbounditem_id"
                  :sort-by.sync="orderBy4"
                  show-empty
                  empty-text="未找到记录"
                  :sort-desc.sync="isSortDirDesc4"
              >

                <!-- Columns -->
                <template #cell(changeId)="data">
                  {{ data.item.change_id }}
                </template>
                <template #cell(changeNo)="data">
                  {{ data.item.change_no }}
                </template>
                <template #cell(status)="data">
                  {{ getCodeLabel('stock_change_apply', data.item.status) }}
                </template>
                <template #cell(creator)="data">{{ getCodeLabel('user', data.item.creator) }}
                </template>
                <template #cell(addTime)="data">
                  {{ toDate(data.item.add_time) }}
                </template>
                <template #cell(warehouseName)="data">
                  {{ data.item.warehouse_name }}
                </template>
                <template #cell(productId)="data">
                  {{ data.item.product_id }}
                </template>
                <template #cell(boxQuantity)="data">
                  {{ data.item.box_quantity }}
                </template>
                <template #cell(name)="data">
                  {{ data.item.name }}
                </template>
                <template #cell(stockId)="data">
                  {{ data.item.stock_id }}
                </template>
                <template #cell(oldsubqty)="data">
                  {{ data.item.oldsubqty }}
                </template>


              </b-table>
              <div class="mx-2 mb-2" v-show="dataMeta4.timeLineShow">
                <b-row>

                  <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-start"
                  >
                    <span class="text-muted">显示 {{ dataMeta4.from }} - {{ dataMeta4.to }} / {{ dataMeta4.of }} 记录</span>
                  </b-col>
                  <!-- Pagination -->
                  <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  >

                    <b-pagination
                        v-model="start4"
                        :total-rows="listTotals4"
                        :per-page="limit"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                        />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </div>
            </app-timeline-item>
            <!-- 占用中的订货单 -->
            <app-timeline-item
                variant="success"
                icon="FileTextIcon"
            >

              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h6 v-show="!dataMeta5.timeLineShow">无占用中的订货单</h6><h6 v-show="dataMeta5.timeLineShow">占用中的订货单</h6>
              </div>
              <b-table
                  id="table5"
                  v-show="dataMeta5.timeLineShow"
                  ref="refListTable5"
                  class="position-relative"
                  :items="dataList5"
                  responsive
                  :fields="tableColumns5"
                  primary-key="itemId"
                  :sort-by.sync="orderBy5"
                  show-empty
                  empty-text="未找到记录"
                  :sort-desc.sync="isSortDirDesc5"
              >

                <!-- Columns -->
                <template #cell(orderId)="data">
                  {{ data.item.order_id }}
                </template>
                <template #cell(orderNo)="data">
                  {{ data.item.order_no }}
                </template>
                <template #cell(salesName)="data">
                  {{ data.item.sales_name }}
                </template>
                <template #cell(warehouseName)="data">
                  {{ data.item.warehouse_name }}
                </template>
                <template #cell(status)="data">
                  {{ getCodeLabel('pre_order_status', data.item.status) }}
                </template>
                <template #cell(creator)="data">
                  {{ getCodeLabel('user', data.item.creator) }}
                </template>
                <template #cell(addTime)="data">
                  {{ toDate(data.item.add_time) }}
                </template>
                <template #cell(productId)="data">
                  {{ data.item.product_id }}
                </template>
                <template #cell(name)="data">
                  {{ data.item.name }}
                </template>
                <template #cell(salesQty)="data">
                  {{ data.item.sales_qty }}
                </template>

              </b-table>
              <div class="mx-2 mb-2" v-show="dataMeta5.timeLineShow">
                <b-row>

                  <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-start"
                  >
                    <span class="text-muted">显示 {{ dataMeta5.from }} - {{ dataMeta5.to }} / {{ dataMeta5.of }} 记录</span>
                  </b-col>
                  <!-- Pagination -->
                  <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  >

                    <b-pagination
                        v-model="start5"
                        :total-rows="listTotals5"
                        :per-page="limit"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                        />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </div>
            </app-timeline-item>
            <!-- 占用中的采购退料出库单 -->
            <app-timeline-item
                variant="success"
                icon="FileTextIcon"
            >

              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h6 v-show="!dataMeta6.timeLineShow">无占用中的采购退料出库单</h6><h6 v-show="dataMeta6.timeLineShow">占用中的采购退料出库单</h6>
              </div>
              <b-table
                  id="table6"
                  v-show="dataMeta6.timeLineShow"
                  ref="refListTable6"
                  class="position-relative"
                  :items="dataList6"
                  responsive
                  :fields="tableColumns6"
                  primary-key="itemId"
                  :sort-by.sync="orderBy6"
                  show-empty
                  empty-text="未找到记录"
                  :sort-desc.sync="isSortDirDesc6"
              >

                <!-- Columns -->
                <template #cell(orderId)="data">
                  {{ data.item.order_id }}
                </template>
                <template #cell(orderNo)="data">
                  {{ data.item.order_no }}
                </template>
                <template #cell(status)="data">
                  {{ getCodeLabel('stock_change_apply', data.item.status) }}
                </template>
                <template #cell(creator)="data">{{ getCodeLabel('user', data.item.creator) }}
                </template>
                <template #cell(addTime)="data">
                  {{ toDate(data.item.add_time) }}
                </template>
                <template #cell(warehouseName)="data">
                  {{ data.item.warehouse_name }}
                </template>
                <template #cell(productId)="data">
                  {{ data.item.product_id }}
                </template>
                <template #cell(name)="data">
                  {{ data.item.name }}
                </template>
                <template #cell(stockId)="data">
                  {{ data.item.stock_id }}
                </template>
                <template #cell(qty)="data">
                  {{ data.item.qty }}
                </template>


              </b-table>
              <div class="mx-2 mb-2" v-show="dataMeta6.timeLineShow">
                <b-row>

                  <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-start"
                  >
                    <span class="text-muted">显示 {{ dataMeta6.from }} - {{ dataMeta6.to }} / {{ dataMeta6.of }} 记录</span>
                  </b-col>
                  <!-- Pagination -->
                  <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  >

                    <b-pagination
                        v-model="start6"
                        :total-rows="listTotals6"
                        :per-page="limit"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                        />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </div>
            </app-timeline-item>
            <!-- 占用中的库位转换单 -->
            <app-timeline-item
                variant="success"
                icon="FileTextIcon"
            >

              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h6 v-show="!dataMeta7.timeLineShow">无占用中的库位转换单</h6><h6 v-show="dataMeta7.timeLineShow">占用中的库位转换单</h6>
              </div>
              <b-table
                  id="table7"
                  v-show="dataMeta7.timeLineShow"
                  ref="refListTable7"
                  class="position-relative"
                  :items="dataList7"
                  responsive
                  :fields="tableColumns7"
                  primary-key="itemId"
                  :sort-by.sync="orderBy7"
                  show-empty
                  empty-text="未找到记录"
                  :sort-desc.sync="isSortDirDesc7"
              >

                <!-- Columns -->
                <template #cell(changeId)="data">
                  {{ data.item.change_id }}
                </template>
                <template #cell(changeNo)="data">
                  {{ data.item.change_no }}
                </template>
                <template #cell(status)="data">
                  {{ getCodeLabel('pre_order_status', data.item.status) }}
                </template>
                <template #cell(creator)="data">
                  {{ getCodeLabel('user', data.item.creator) }}
                </template>
                <template #cell(addTime)="data">
                  {{ toDate(data.item.add_time) }}
                </template>
                <template #cell(warehouseName)="data">
                  {{ data.item.warehouse_name }}
                </template>
                <template #cell(productId)="data">
                  {{ data.item.product_id }}
                </template>
                <template #cell(name)="data">
                  {{ data.item.name }}
                </template>
                <template #cell(stockId)="data">
                  {{ data.item.stock_id }}
                </template>
                <template #cell(stockQty)="data">
                  {{ data.item.stock_qty }}
                </template>


              </b-table>
              <div class="mx-2 mb-2" v-show="dataMeta7.timeLineShow">
                <b-row>

                  <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-start"
                  >
                    <span class="text-muted">显示 {{ dataMeta7.from }} - {{ dataMeta7.to }} / {{ dataMeta7.of }} 记录</span>
                  </b-col>
                  <!-- Pagination -->
                  <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  >

                    <b-pagination
                        v-model="start7"
                        :total-rows="listTotals7"
                        :per-page="limit"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                        />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </div>
            </app-timeline-item>

          </app-timeline>

        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {BRow, BCol, BCard,
  BImg, BAvatar, BMedia,BTable, BButton, BCollapse, VBToggle, BListGroup, BListGroupItem, BAvatarGroup, BBadge, VBTooltip,} from 'bootstrap-vue'

import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import store from "@/store";
import stockoccupiedStore from "@/views/apps/stockoccupied/stockoccupiedStore";
import {onUnmounted, ref} from "@vue/composition-api";
import stockOccupiedList from "@/views/apps/stockoccupied/stockOccupiedList";
import {avatarText, toDate,getCode, getCodeColor, getCodeLabel, getCodeOptions} from "@core/utils/filter";
import {useRouter} from "@core/utils/utils";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BImg,
    BAvatar,
    BMedia,
    BTable,
    BButton,
    BCollapse,
    VBToggle,
    BListGroup,
    BListGroupItem,
    BAvatarGroup,
    BBadge,
    VBTooltip,
    AppTimeline,
    AppTimelineItem,
  },
  data() {
    return {

    }
  },
  props:{
    warehouse_id: {
      default:0
    },
    product_id: {
      default:0
    },
    stock_id: {
      default:0
    },
  },
  setup(props) {

    // Register module
    if (!store.hasModule('stockoccupied')) store.registerModule('stockoccupied', stockoccupiedStore)

    onUnmounted(() => {
      if (store.hasModule('stockoccupied')) store.unregisterModule('stockoccupied')
    })
    const router = useRouter()

    const {
      refetchData,
      limitOptions,
      limit,

      dataList1,
      tableColumns1,
      start1,
      listTotals1,
      dataMeta1,
      orderBy1,
      isSortDirDesc1,
      refListTable1,

      dataList2,
      tableColumns2,
      start2,
      listTotals2,
      dataMeta2,
      orderBy2,
      isSortDirDesc2,
      refListTable2,

      dataList3,
      tableColumns3,
      start3,
      listTotals3,
      dataMeta3,
      orderBy3,
      isSortDirDesc3,
      refListTable3,

      dataList4,
      tableColumns4,
      start4,
      listTotals4,
      dataMeta4,
      orderBy4,
      isSortDirDesc4,
      refListTable4,

      dataList5,
      tableColumns5,
      start5,
      listTotals5,
      dataMeta5,
      orderBy5,
      isSortDirDesc5,
      refListTable5,

      dataList6,
      tableColumns6,
      start6,
      listTotals6,
      dataMeta6,
      orderBy6,
      isSortDirDesc6,
      refListTable6,

      dataList7,
      tableColumns7,
      start7,
      listTotals7,
      dataMeta7,
      orderBy7,
      isSortDirDesc7,
      refListTable7,

    } = stockOccupiedList(props.warehouse_id||0,props.product_id||0,props.stock_id||'');
    const methods = {

      /*rrefreshTable  (){
        this.refetchData()
      },

      esetCondition() {
        this.refetchData()
      },*/
    }
    return {
      ...methods,
      BImg,
      BAvatar,
      BMedia,
      BTable,
      BButton,
      BCollapse,
      VBToggle,
      BListGroup,
      BListGroupItem,
      BAvatarGroup,
      BBadge,
      VBTooltip,
      AppTimeline,
      AppTimelineItem,

      refetchData,
      limitOptions,
      limit,

      dataList1,
      tableColumns1,
      start1,
      listTotals1,
      dataMeta1,
      orderBy1,
      isSortDirDesc1,
      refListTable1,

      dataList2,
      tableColumns2,
      start2,
      listTotals2,
      dataMeta2,
      orderBy2,
      isSortDirDesc2,
      refListTable2,

      dataList3,
      tableColumns3,
      start3,
      listTotals3,
      dataMeta3,
      orderBy3,
      isSortDirDesc3,
      refListTable3,

      dataList4,
      tableColumns4,
      start4,
      listTotals4,
      dataMeta4,
      orderBy4,
      isSortDirDesc4,
      refListTable4,

      dataList5,
      tableColumns5,
      start5,
      listTotals5,
      dataMeta5,
      orderBy5,
      isSortDirDesc5,
      refListTable5,

      dataList6,
      tableColumns6,
      start6,
      listTotals6,
      dataMeta6,
      orderBy6,
      isSortDirDesc6,
      refListTable6,

      dataList7,
      tableColumns7,
      start7,
      listTotals7,
      dataMeta7,
      orderBy7,
      isSortDirDesc7,
      refListTable7,

      // Filter
      avatarText,
      getCodeOptions,
      toDate,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
