<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        className="mb-0"
    >

      <div class="m-2">


        <b-row>
          <search @refreshTable=refetchData />
          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              className="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>
          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
          ref="refListTable"
          className="position-relative"
          :items="searchList"
          responsive
          hover
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
      >

        <template #cell(creator)="{value}">
          {{ getCodeLabel('user', value) }}
        </template>

        <template #cell(inbound_no)="data">
          {{ data.item.ext.inboundNo }}
        </template>

        <template #cell(outbound_no)="data">
          <feather-icon
              :icon="data.detailsShowing ? 'MinusSquareIcon' : 'PlusSquareIcon'"
              @click="toggleDetail(data)"
          />
          {{ data.value }}
        </template>

        <template #cell(status)="{value}">
          <b-badge
              pill
              :variant="`light-${getCodeColor('purchase_outbound_status',value)}`"
          >
            {{ getCodeLabel('purchase_outbound_status', value) }}
          </b-badge>
        </template>

        <template #cell(reason)="{value}">
          {{ getCodeLabel('purchase_returnbound_reason', value) }}
        </template>

        <template #cell(remark)="data">
          <div :id="`remark-row-${data.item.id}`">
            {{ data.value.substring(0, 3) }}
            <span v-if="data.value.length > 3"> ...</span>
          </div>
          <b-tooltip
              :target="`remark-row-${data.item.id}`"
              placement="top"
              v-if="data.value"
          >
            {{ data.value }}
          </b-tooltip>
        </template>


        <template #row-details="data">
          <purchase-outbound-item-list ref="itemList" :id="data.item.outbound_id" @refresh="refresh(data)" :readonly_all="data.item.status === 2"
          >
          </purchase-outbound-item-list>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
              top
          >

            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
                :to="{ name: 'apps-purchaseoutbound-view', query: { id: data.item.id },params:{readonly:true} }"
            >
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">浏览</span>
            </b-dropdown-item>

            <div v-if="data.item.status === 1">
              <b-dropdown-item :to="{ name: 'apps-purchaseoutbound-edit', query: { id: data.item.id } }">
                <feather-icon icon="EditIcon"/>
                <span class="align-middle ml-50">编辑</span>
              </b-dropdown-item>
              <b-dropdown-item @click="approve(data.item.id,2)">
                <feather-icon icon="EditIcon"/>
                <span class="align-middle ml-50">提交审核</span>
              </b-dropdown-item>
            </div>

            <div v-if="auth && data.item.status === 2">
              <b-dropdown-item @click="approve(data.item.id,3)">
                <feather-icon icon="EditIcon"/>
                <span class="align-middle ml-50">通过</span>
              </b-dropdown-item>

              <b-dropdown-item @click="approve(data.item.id,1)">
                <feather-icon icon="EditIcon"/>
                <span class="align-middle ml-50">驳回</span>
              </b-dropdown-item>
            </div>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted, reactive, onMounted, toRefs, provide } from '@vue/composition-api'
import { avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'
import purchaseoutboundUseList from './purchaseoutboundUseList'
import { getUserData } from '@/auth/utils'
import PurchaseReturnboundItemList from '@/views/apps/purchasereturnbounditem/PurchaseReturnboundItemList'
import Search from './search'
import { useToast } from 'vue-toastification/composition'
import purchaseoutboundStore from '@/views/apps/purchaseoutbound/purchaseoutboundStore'
import PurchaseOutboundItemList from '@/views/apps/purchaseoutbounditem/PurchaseOutboundItemList'
import { useRouter } from '@core/utils/utils'
import Ripple from "vue-ripple-directive";
import axios from "@/libs/axios";

export default {
  components: {
    PurchaseOutboundItemList,
    Search,
    PurchaseReturnboundItemList,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  directives: {
    Ripple,
  },
  setup() {
    // Register module
    if (!store.hasModule('purchaseoutbound')) store.registerModule('purchaseoutbound', purchaseoutboundStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('purchaseoutbound')) store.unregisterModule('purchaseoutbound')
    })

    const {route} = useRouter()


    const state = reactive({
      auth: false
    })

    onMounted(() => {

      const { role_id } = getUserData()

      /*if (role_id === 18 || role_id === 12) {
        state.auth = true
      }*/
    })

    const toast = useToast()
    const methods = {
      refresh(row){
        row.toggleDetails()
        refetchData()
      },
      toggleDetail(row) {
        row.toggleDetails()
      },
      approve(id, status) {
        store.dispatch('purchaseoutbound/approve', {
          id: id,
          status: status
        })
            .then(res => {
              if (res.data.code === 0) {
                if (status === 1) {
                  toast.success('驳回成功')
                } else if (status === 3) {
                  toast.success('审批成功')
                }
                refetchData()
              } else {
                toast.error(res.data.data)
              }
            })
      },
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

    } = purchaseoutboundUseList({
     returnbound_id: route.value.query.id,
      outbound_id: route.value.query.outbound_id,
    })

    return {
      ...toRefs(state),
      ...methods,

      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
      toTime,
      toDate,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
